import { Box, Button, Card, CardActionArea, Container, Grid, Stack, Typography } from '@mui/material';
import ServiceCard from '../ServiceCard';
import { useEffect, useState } from 'react';
import demo from '../../assets/demo.gif'
import back from '../../assets/back.png'
import app from '../../assets/app.png'
import ui from '../../assets/ui.png'
import { useParams } from 'react-router-dom';

export default function ServicesContent() {

  const [activeCard, setActiveCard] = useState('front')
  const { navCard } = useParams()

  function cardHandler(card) {
    setActiveCard(card)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    navCard ? setActiveCard(navCard) : setActiveCard('front')
  }, [navCard])

  function Front() {
    return <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Box
        component='img'
        src={demo}
        sx={{
          height: '80%',
          width: '80%',
          backgroundColor: '#ffffff',
          borderRadius: 5,
        }}
      />
      <Typography variant='caption' sx={{ mb: 2, mt: 4, mx: 4 }}>"Pixelalogic takes your vision and brings it to life with unrivaled front-end design and engineering expertise. With a blend of cutting-edge technology and human-centered design, we create engaging, intuitive user interfaces that propel your business ahead."</Typography>
    </Box>
  }

  function Back() {
    return <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Box
        component='img'
        src={back}
        sx={{
          height: '80%',
          width: '80%',
          backgroundColor: '#ffffff',
          borderRadius: 5,
        }}
      />
      <Typography variant='caption' sx={{ mb: 2, mt: 4, mx: 4 }}>"Unlock the full potential of your business with Pixelalogic's robust and scalable back-end engineering services. We guarantee secure, efficient, and adaptable solutions that smoothly handle your evolving business needs."</Typography>
    </Box>
  }

  function IOS() {
    return <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Box
        component='img'
        src={app}
        sx={{
          height: '80%',
          width: '80%',
          backgroundColor: '#ffffff',
          borderRadius: 5,
        }}
      />
      <Typography variant='caption' sx={{ mb: 2, mt: 4, mx: 4 }}>"Experience unparalleled quality with Pixelalogic's React Native iOS application development services. We leverage the power of React Native to build performant, stunning, and intuitive apps that feel native to iOS."</Typography>
    </Box>
  }
  function UI() {
    return <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Box
        component='img'
        src={ui}
        sx={{
          height: '80%',
          width: '80%',
          backgroundColor: '#ffffff',
          borderRadius: 5,
        }}
      />
      <Typography variant='caption' sx={{ mb: 2, mt: 4, mx: 4 }}>"Pixelalogic is your perfect partner for UI/UX design. We combine creativity and user-focused thinking to make easy-to-use, attractive interfaces. Trust us to turn your digital platforms into places where users love to spend their time, driving your brand and business forward."</Typography>
    </Box>
  }

  function PickContent() {
    if (activeCard === 'front') return <Front />
    if (activeCard === 'back') return <Back />
    if (activeCard === 'iOS') return <IOS />
    if (activeCard === 'UI') return <UI />
  }

  return (
    <div id='Services'>
      <Container maxWidth='lg' sx={{ py: { xs: 6, md: 10 }, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

        <Grid container >
          <Grid item xs={12} md={4}>
            <Typography variant='h1' sx={{ mb: {xs: 0, sm: 4} }}>What we do.</Typography>
            <Grid container spacing={2} sx={{ pr: { xs: 0, md: 0 }, pt: 2 }}>
              <Grid item xs={6} sm={8}>
                <ServiceCard cardHandler={cardHandler} active={activeCard === 'front'} card={'front'} small={true}>Front-end</ServiceCard>
              </Grid>
              <Grid item xs={6} sm={8}>
                <ServiceCard cardHandler={cardHandler} active={activeCard === 'back'} card={'back'} small={true}>Backend</ServiceCard>
              </Grid>
              <Grid item xs={6} sm={8}>
                <ServiceCard cardHandler={cardHandler} active={activeCard === 'iOS'} card={'iOS'} small={true}>iOS Apps</ServiceCard>
              </Grid>
              <Grid item xs={6} sm={8}>
                <ServiceCard cardHandler={cardHandler} active={activeCard === 'UI'} card={'UI'} small={true}>UI/UX design</ServiceCard>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ mt: {xs: 8, sm: 5} }}>
              <PickContent />
            </Box>

          </Grid>
        </Grid>

      </Container>
    </div>
  );
}
