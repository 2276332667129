import { Box, Button, Card, CardActionArea, Container, Grid, Stack, Typography } from '@mui/material';
import * as React from 'react';
import ServiceCard from './ServiceCard';
import { useNavigate } from 'react-router-dom';



export default function SecondContent() {

  const navigate = useNavigate()

  function cardHandler(card){
    navigate(`/services/${card}`)
  }

  return (
    <div id='Services'>
      <Container maxWidth='lg' sx={{ py: { xs: 6, md: 12 }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Stack sx={{mr: 4}}>
              <Typography variant='h1'>Improve your bottom-line.</Typography>
              <Box sx={{ backgroundColor: 'primary.main', width: '12vw', height: '0.2em', my: 3 }} />
              <Typography variant='body1'>
              Pixelogic partners with small to medium-sized businesses to design and build custom software that improves key business metrics.
              </Typography>
              <Button variant='contained' sx={{ px: 0.7, py: 1.4, maxWidth: 200, mt: 4 }}>Learn More</Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2} sx={{px: {xs: 1, md: 8}, pt: {xs: 6, md: 0}}}>
              <Grid item xs={6}>
                <ServiceCard cardHandler={cardHandler} card={'front'}>Front-end</ServiceCard>
              </Grid>
              <Grid item xs={6} >
                <ServiceCard cardHandler={cardHandler} card={'back'}>Backend</ServiceCard>
              </Grid>
              <Grid item xs={6} >
                <ServiceCard cardHandler={cardHandler} card={'iOS'}>iOS Apps</ServiceCard>
              </Grid>
              <Grid item xs={6} >
                <ServiceCard cardHandler={cardHandler} card={'UI'}>UI/UX design</ServiceCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
