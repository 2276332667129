import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#2979FF',
      light: 'rgba(41,121,255, 0.1)'
    },
    secondary: {
      main: '#C5CED9',
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    h1: {
      fontFamily: 'Hind Siliguri',
      fontWeight: '400',
      fontSize: '3.2rem',
      color: '#000000',
      letterSpacing: '0.01em'
    },
    h2: {
      fontFamily: 'Hind Siliguri',
      fontWeight: '400',
      fontSize: '2.4em',
      color: '#313b46'
    },
    h3: {
      fontFamily: 'Hind Siliguri',
      fontWeight: '400',
      fontSize: '1.8em',
      color: '#313b46'

    },
    h4: {
      fontFamily: 'Hind Siliguri',
      fontWeight: '400',
      fontSize: '2em',
      color: '#313b46'

    },
    h5: {
      fontFamily: 'Hind Siliguri',
      fontWeight: '400',
      fontSize: '1.8em',
      color: '#313b46'

    },
    h6: {
      fontFamily: 'Hind Siliguri',
      fontWeight: '400',
      fontSize: '1.4em',
      color: '#313b46'
    },
    subtitle1: {
      fontFamily: 'Hind Siliguri',
      fontWeight: 'bold',
      fontSize: '1.2em',
    },
    subtitle2: {
      fontFamily: 'Hind Siliguri',
      fontWeight: 'bold',
      fontSize: '1em',
      textTransform: 'capitalize',
      color: '#313b46',
    },
    body1: {
      fontFamily: 'Roboto',
      fontWeight: '400',
      fontSize: '1em',
      color: '#313b46'
    },
    body2: {
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      fontSize: '0.8em',
      color: '#7C8694',
      letterSpacing: '0.02em'
    },
    button: {
      fontFamily: 'Hind Siliguri',
      fontWeight: 'bold',
      fontSize: '1.1em',
      textTransform: 'none',
      letterSpacing: '0.04em'
    },
    caption: {
      fontFamily: 'Roboto',
      fontWeight: '400',
      fontStyle: 'italic',
      fontSize: '1em',
      letterSpacing: 0,
      color: '#313b46'
    },
    overline: {
      fontFamily: 'Hind Siliguri',
      fontWeight: 'bold',
      fontSize: '1em',
      color: '#313b46'
    }
  },
});

export default theme;
