import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';
import WorkOne from '../components/Work/WorkOne';
import WorkTwo from '../components/Work/WorkTwo';
import WorkThree from '../components/Work/WorkThree';
import { useEffect } from 'react';

export default function Work() {

  useEffect(() => {
    window.scrollTo(0, 0);
  },[])

  return (
    <>
      <NavBar/>
      <WorkTwo/>
      <WorkOne/>
      <WorkThree/>
      <ContactForm/>
      <Footer/>
    </>
  );
}
