import { useEffect } from 'react';
import NavBar from '../components/NavBar';
import LandingContent from '../components/LandingContent';
import SecondContent from '../components/SecondContent';
import ThirdContent from '../components/ThirdContent';
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <>
      <NavBar/>
      <LandingContent/>
      <SecondContent/>
      <ThirdContent/>
      <ContactForm/>  
      <Footer/>
    </>
  );
}
