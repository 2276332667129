import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import { useEffect } from 'react';
import TermsContent from '../components/TermsContent';

export default function About() {

  useEffect(() => {
    window.scrollTo(0, 0);
  },[])

  return (
    <>
      <NavBar/>
      <TermsContent/>
      <Footer/>
    </>
  );
}
