import { useEffect } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import ServicesContent from '../components/ServicesPage/ServicesContent';

export default function Services() {

  useEffect(() => {
    window.scrollTo(0, 0);
  },[])

  return (
    <>
      <NavBar/>
      <ServicesContent/>
      <Footer/>
    </>
  );
}
