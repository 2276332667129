import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import { Button, Stack, TextField, Typography } from '@mui/material';
import logo from '../assets/logo.svg'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Copyright() {
  return (
    <Box>
      <Typography variant='body2' sx={{ color: '#FFFFFF' }}>© Pixelalogic 2023</Typography>
    </Box>
  );
}

export default function Footer() {

  const [submitted, setSubmitted] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  return (
    <Typography
      component='footer'
      sx={{ display: 'flex', bgcolor: 'secondary.light' }}
    >
      <Container sx={{ my: { xs: 2, sm: 8 }, display: 'flex' }}>
        <Grid container spacing={2} display={'flex'} alignItems={'center'}>
          <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 4 }}>
            <Stack justifyContent='center' alignItems='center'>
              <Box
                component='img'
                src={logo}
                sx={{
                  display: 'flex',
                  width: 65,
                  height: 65,
                  m: 1.5,
                }}
              />
              <Copyright />
            </Stack>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography variant='h6' marked='left' gutterBottom>
              Connect
            </Typography>
            <Box component='ul' sx={{ m: 0, listStyle: 'none', p: 0 }}>
              <Box component='li' sx={{ py: 1 }}>
                <Link sx={{ cursor: 'pointer' }} onClick={() => navigate('/contact')} >Contact us</Link>
              </Box>
              <Box component='li' sx={{ py: 1 }}>
                <Link sx={{ cursor: 'pointer' }} onClick={() => navigate('/services')} >Services</Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography variant='h6' marked='left' gutterBottom>
              Legal
            </Typography>
            <Box component='ul' sx={{ m: 0, listStyle: 'none', p: 0 }}>
              <Box component='li' sx={{ py: 1 }}>
                <Link sx={{ cursor: 'pointer' }} onClick={() => navigate('/terms')} >Terms</Link>
              </Box>
              <Box component='li' sx={{ py: 1 }}>
                <Link sx={{ cursor: 'pointer' }} onClick={() => navigate('/contact')} >Privacy</Link>
              </Box>
            </Box>
          </Grid>
          <Grid item sx={{mb: 8}}>
            {
              submitted
                ? <Typography>Thank you!</Typography>
                : <>
                <Typography variant='body2' sx={{mt: 4}}>Subscribe to our newsletter:</Typography>
                  <Box component='form' onSubmit={() => setSubmitted(true)} noValidate sx={{ mt: 0 }}>
                    <TextField
                      margin='normal'
                      fullWidth
                      variant='outlined'
                      id='email'
                      label='Email Address'
                      name='email'
                      autoComplete='email'
                      size='small'
                    />
                    <Button
                      type='submit'
                      variant='contained'
                      size='small'
                      sx={{ mt: 1, mb: 0 }}
                    >Subscribe</Button>
                  </Box></>
            }


          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}