import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Button, Grid, Stack, Typography } from '@mui/material';
import landingImage from '../assets/dishi_work.png'
import { useNavigate } from 'react-router-dom';

export default function LandingContent() {
  const navigate = useNavigate()
  return (
    <>
      <Container maxWidth='lg' sx={{ py: {xs: 6, md: 12}, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Grid container spacing={1}>
          <Grid 
            item 
            xs={12} 
            md={4}
            sx={{
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center',
            }}>
              <Stack>
                <Typography variant='h1'>Software solutions for your business.</Typography>
                <Box sx={{backgroundColor: 'primary.main', width: '12vw', height: '0.2em', my: 3}}/>
                <Typography variant='body1' sx={{mr: 4}}>We design custom software to give your business an unfair competitive edge.</Typography>
                <Button variant='contained' onClick={() => navigate('/work')} sx={{px: 0.7, py: 1.4,  maxWidth: 200, mt: 4}}>See Our Work</Button>
              </Stack>
          </Grid>
          <Grid 
            item 
            xs={12} 
            md={8} 
            sx={{
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center'
            }}>
            <Box
              component='img'
              src={landingImage}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '100%',
                borderRadius: 4,
                pt: {xs: 6, sm: 0}
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
