import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';
import AboutContent from '../components/AboutContent';
import { useEffect } from 'react';

export default function About() {

  useEffect(() => {
    window.scrollTo(0, 0);
  },[])

  return (
    <>
      <NavBar/>
      <AboutContent/>
      <ContactForm/>  
      <Footer/>
    </>
  );
}
