import { Box, Button, Checkbox, Container, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';

export default function ContactForm() {

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  return (

    <>
      <Container maxWidth='lg'  sx={{ py: { xs: 6, md: 10 }, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Box sx={{ width: '100%', mb: 5 }}>
          <Typography variant='h1'>Get in touch</Typography>
          <Box sx={{ backgroundColor: 'primary.main', width: 200, height: 4, mt: 2 }} />

          <div id='contact'>
            <Box component='form' action='https://getform.io/f/b6b4ef21-42e7-48da-bfdc-dbe76d609ccb' method='post' sx={{ mt: 8 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete='given-name'
                    name='firstName'
                    required
                    fullWidth
                    id='firstName'
                    label='First Name'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id='lastName'
                    label='Last Name'
                    name='lastName'
                    autoComplete='family-name'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id='email'
                    label='Email Address'
                    name='email'
                    autoComplete='email'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    rows={4}
                    required
                    fullWidth
                    name='message'
                    label='message'
                    id='message'
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox value='allowExtraEmails' color='primary' />}
                    label='Subscribe to newsletter.'
                  />
                </Grid>
              </Grid>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </Button>
            </Box>
          </div>

        </Box>
      </Container>
    </>
  );
}
