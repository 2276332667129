import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Button, Grid, Stack, Typography } from '@mui/material';
import landingImage from '../../assets/inventory.png'
import { useNavigate } from 'react-router-dom';

export default function WorkTwo() {
  const navigate = useNavigate()
  return (
    <>
      <Container maxWidth='lg' sx={{ py: { xs: 6, md: 16 }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Box
              component='img'
              src={landingImage}
              sx={{
                display: { xs: 'none', sm: 'flex' },
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '80%',
                borderRadius: 4,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Stack>
              <Typography variant='h1'>Cloud Inventory Platform</Typography>
              <Box sx={{ backgroundColor: 'primary.main', width: '12vw', height: '0.2em', my: 3 }} />
              <Box
                component='img'
                src={landingImage}
                sx={{
                  display: { xs: 'flex', sm: 'none' },
                  justifyContent: 'center',
                  alignItems: 'center',
                  maxWidth: '100%',
                  borderRadius: 4,
                  pb: 4
                }}
              />
              <Typography variant='body1'>We developed a web app that enables a distribution company to manage its network of suppliers. This app allows suppliers to handle inventory in the cloud and receive purchase orders for guaranteed inventory, thus eliminating the need for back-and-forth emails and resolving issues with limited inventory visibility.</Typography>
              <Button variant='contained' onClick={() => navigate('/contact')} sx={{ px: 0.7, py: 1.4, maxWidth: 200, mt: 4 }}>Talk to us</Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
