import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import eduardo from '../assets/eduardo.png'


export default function AboutContent() {
  return (
    <div id='Work'>
      <Container maxWidth='lg' sx={{ pt: { xs: 6, md: 6 }, pb: { xs: 6, md: 4 }, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Box sx={{ width: '100%', mb: 5 }}>
          <Typography variant='h2'>About</Typography>
          <Box sx={{ backgroundColor: 'primary.main', width: 120, height: 3, mt: 2 }} />
        </Box>

        <Grid container sx={{ display: 'flex' }}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography component='h2' variant='h5'>
                Eduardo Umaña
              </Typography>
              <Typography variant='subtitle1' paragraph>
                Founder, Pixelalogic LLC.
              </Typography>
              <Typography variant='body1' sx={{ mt: 0 }}>
                With a unique blend of engineering and entrepreneurial expertise, Eduardo brings a proven track record of success in software development and business management. Eduardo has led full-stack development initiatives multiple times, creating sophisticated web and mobile software solutions that transformed business operations for his clients. With expertise in a diverse array of front-end and back-end technologies such as React, React Native, and Node, he has successfully launched and maintained apps that drove significant revenue increases and streamlined processes. 
              </Typography>
              <Typography variant='body1' sx={{ mt: 2 }}>
                As an effective leader, Eduardo has experience managing multidisciplinary teams, demonstrating a rare ability to bridge the gap between technology, design, and business strategy. Eduardo invented and engineered the VacOne Coffee Air Brewer and started a company to market his creation before selling the business to a larger kitchen appliance manufacturer. This experience as a business operator, along with his broad engineering expertise, enables him to identify real-world problems and solve them with smart, efficient technical solutions. Eduardo holds a B.S. in Engineering from Swarthmore College, with a concentration in Electrical and Computer Engineering.
              </Typography>

            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Paper
              elevation={10}
              component='img'
              sx={{
                height: 300,
                display: { xs: 'flex', md: 'flex' },
                borderRadius: 50,
                mt: {xs: 6, sm: 0}
              }}
              alt='Eduardo'
              src={eduardo}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
