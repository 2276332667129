import { Box, Button, Card, CardActionArea, Container, Grid, Stack, Typography } from '@mui/material';
import * as React from 'react';



export default function ServiceCard({children, cardHandler, card, small, active}) {
  return (
    <>
      <Card elevation={0} sx={{ borderBottom: 'solid', borderBottomColor: 'primary.main', borderRadius: 0, borderBottomWidth: 2, backgroundColor: active ? 'primary.light' : '#FFFFFF' }}>
        <CardActionArea
          onClick={()=>cardHandler(card)}
          sx={{
            height: small ? 60 : 80,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            p: 1,
          }}> 
          <Box sx={{}}>
            <Typography variant={small ? 'h3':'h2'}>{children}</Typography>
          </Box>
        </CardActionArea>
      </Card>
    </>
  );
}
