import { Box, Button, Card, CardActionArea, Container, Grid, Stack, Typography } from '@mui/material';
import * as React from 'react';
import hero from '../assets/INVENTARIO.png'



export default function ThirdContent() {
  return (
    <>
      <Container maxWidth='lg' sx={{ py: { xs: 6, md: 12 }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Grid container>
          <Grid item xs={12} md={5}>
            <Stack>
              <Typography variant='h1'>Boost your business advantage.</Typography>
              <Box sx={{ backgroundColor: 'primary.main', width: '12vw', height: '0.2em', my: 3 }} />
              <Typography variant='body1' sx={{mr: 4}}>Unleash your business's full potential with our custom software development services, tailor-made to streamline your operations and propel you ahead of the competition.</Typography>
              <Button variant='contained' sx={{ px: 0.7, py: 1.4, maxWidth: 200, mt: 4 }}>Learn More</Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={7}>
          <Box
              component='img'
              src={hero}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                maxHeight: '100%',
                maxWidth: '100%',
                borderRadius: 4,
                pt: {xs: 6, md: 0}
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
