import NavBar from '../components/NavBar';
import LandingContent from '../components/LandingContent';
import SecondContent from '../components/SecondContent';
import ThirdContent from '../components/ThirdContent';
import Clients from '../components/Clients';
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';
import { useEffect } from 'react';

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <>
      <NavBar/>
      <ContactForm/>
      <Footer/>
    </>
  );
}
