import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import eduardo from '../assets/eduardo.png'


export default function TermsContent() {
  return (
    <div id='Work'>
      <Container maxWidth='lg' sx={{ pt: { xs: 6, md: 6 }, pb: { xs: 6, md: 4 }, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Box sx={{ width: '100%', mb: 5 }}>
          <Typography variant='h2'>Terms of Service</Typography>
          <Box sx={{ backgroundColor: 'primary.main', width: 120, height: 3, mt: 2 }} />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='body1' sx={{ mb: 6 }}>
            Terms of Service:

            Welcome to Pixelalogic LLC. By accessing our website, you are agreeing to comply with and be bound by the following Terms of Service.

            Acceptance of Terms: These terms govern your use of our website; by using our website, you accept these terms in full. If you disagree with these terms or any part of these terms, you must not use our website.

            License to use website: Unless otherwise stated, Pixelalogic LLC owns the intellectual property rights in the website and material on the website. You may view, download for caching purposes only, and print pages from the website for your own personal use, subject to the restrictions set out below and elsewhere in these terms of service.

            Limitations of Liability: The information on this website is provided free-of-charge, and you acknowledge that it would be unreasonable to hold us liable in respect of this website and the information on this website.

            Restricted Access: Access to certain areas of our website is restricted. We reserve the right to restrict access to other areas of our website, or indeed our whole website, at our discretion.

            Variation: We may revise these terms of service from time-to-time. Revised terms of service will apply to the use of our website from the date of the publication of the revised terms of service on our website.

            Law and Jurisdiction: These terms will be governed by and construed in accordance with the laws of [Your Jurisdiction], and any disputes relating to these terms of service will be subject to the [non-]exclusive jurisdiction of the courts of [Your Jurisdiction].

            Privacy Policy:

            At Pixelalogic LLC, we respect your privacy and are committed to protecting the personal information that you share with us.

            Information Collection and Use: We collect personal information when you use our website, including but not limited to your name, email address, and browser information. We use this information for fulfilling your requests for services, improving our services, and contacting you.

            Information Sharing: We do not sell, rent, or share your personal information with third parties outside of Pixelalogic LLC without your express consent.

            Cookies: We use "cookies" to enhance your experience with our website. You can disable cookies at any time by changing your web browser's settings.

            Security: We implement reasonable security measures to protect against unauthorized access to or unauthorized alteration, disclosure, or destruction of your personal information.

            Changes to this Privacy Policy: We reserve the right to amend this Privacy Policy at any time. Any changes will be posted on this page.

            This is a basic terms and privacy policy, and it may not suit your specific circumstances. It is strongly recommended that you seek legal advice to draft these documents to ensure they comply with all legal requirements and appropriately protect your interests.

            *Note: In the "Law and Jurisdiction" section of the Terms of Service, and anywhere else in these documents where jurisdiction needs to be mentioned, please input the relevant jurisdiction (i.e., the state or country where your business operates).
          </Typography>
        </Box>
      </Container>
    </div>
  );
}
