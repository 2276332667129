import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Logo from '../assets/logo.svg'
import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';


const pages = ['Services', 'Work', 'About us'];
const links = ['services', 'work', 'about']

function NavBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate()

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogo = () => {
    window.scrollTo(0, 0);
    navigate('/')
  }

  return (
    <AppBar position='sticky' elevation={0}>
      <Container maxWidth='lg' sx={{ py: 2 }}>
        <Toolbar disableGutters>
          <Box onClick={() => handleLogo()} sx={{cursor: 'pointer'}}>
            <Box
              component='img'
              sx={{
                height: 86,
                display: { xs: 'none', md: 'flex' },
              }}
              alt='Pixelalogic Logo'
              src={Logo}
            />
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page,i) => (
                <MenuItem key={page} onClick={() => navigate(`/${links[i]}`)}>
                  <Typography textAlign='center'>{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box onClick={() => handleLogo()} sx={{
            cursor: 'pointer', 
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'}}>
            <Box
              component='img'
              sx={{
                height: 60,
                display: { xs: 'flex', md: 'none' },
                
              }}
              alt='Pixelalogic Logo'
              src={Logo}
            />
          </Box>
          <Box sx={{
            flexGrow: 1,
            pr: 8,
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'flex-end'
          }}>
            {pages.map((page, i) => (
              <Button
                key={page}
                onClick={() => navigate(`/${links[i]}`)}
                sx={{
                  my: 2,
                  mx: 3,
                  color: 'white',
                  display: 'block',
                  fontWeight: '400',
                  fontSize: '1.1em',
                }}
              >
                {page}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0, display: {xs: 'flex', md: 'flex'}}}>
            <Button 
              variant='contained' 
              onClick={() => navigate('/contact')} 
              sx={{ 
                px: {xs: 0.7, md: 1.6}, 
                py: {xs: 0.8, md: 1.2}, 
                backgroundColor: '#f5f9ff', 
                color: 'primary.main',
                ':hover': {
                  backgroundColor: '#ffffff',
                },
                fontSize: {xs: '0.8em', md: '1.1em'}
              }}
              >Let's talk</Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;